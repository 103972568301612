import { useEffect } from 'react'
import { useLocalStorage } from './useLocalStorage'

type ReferalParametersStore = [
  value: string | null,
  set: (value: string) => void,
  remove: () => void
]

export const useReferalParameterStore = (
  name: string,
  value: string | null,
  store = 'referalParamsStorage'
): ReferalParametersStore => {
  const storageKey = `${store}.${name}`

  const [storage, setStorage] = useLocalStorage(storageKey, value)

  const remove = () => {
    setStorage(null)
  }

  useEffect(() => {
    if (typeof value === 'string' && value.length > 0) {
      setStorage(value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return [storage, setStorage, remove]
}
