import React from 'react'
import {
  ButtonGrey,
  ButtonGroup,
  ButtonGroupWithB2B,
  ButtonNewOutline,
  ButtonNewPrimary,
  Title,
  VideoCallIcon,
  Wrap
} from './index.styles'
import { selectDeviceSizeIsMobile } from '../../../../state/reducers/deviceSlice'
import { useSelector } from 'react-redux'

export const QuestionStep = ({ goNext }) => {
  const isMobile = useSelector(selectDeviceSizeIsMobile)
  return (
    <Wrap>
      <VideoCallIcon />
      <Title bold>
        Вы уже занимаетесь
        <br />с психологом в YouTalk?
      </Title>
      <ButtonGroupWithB2B>
        <ButtonGroup>
          <ButtonGrey onClick={goNext} size={isMobile ? 'medium' : 'small'}>
            Нет
          </ButtonGrey>
          <ButtonNewPrimary
            href="/account/login"
            size={isMobile ? 'medium' : 'small'}
            type="externalLink"
          >
            Да
          </ButtonNewPrimary>
        </ButtonGroup>
        <ButtonNewOutline
          href="/b2b_signup"
          size={isMobile ? 'medium' : 'small'}
          type="externalLink"
        >
          Регистрация для b2b клиентов
        </ButtonNewOutline>
      </ButtonGroupWithB2B>
    </Wrap>
  )
}
