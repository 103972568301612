import PickUp from '../../PickUp'
import React from 'react'
import { ButtonGroup, ButtonNewOutline, ButtonNewPrimary } from './index.styles'
import { navigate } from 'gatsby'
import { useNewOpenForm } from '../../GA'

export const HeaderPickUp = ({ hidden }) => {
  const handleNewOpenForm = useNewOpenForm('header')

  if (hidden) return null

  return <PickUp className="header__link" onClick={handleNewOpenForm} />
}

export const HeaderPickUpSm = ({ onClickLoginButton, isLoggedOff }) => {
  const handleNewOpenForm = useNewOpenForm('header')

  return (
    <ButtonGroup>
      <ButtonNewPrimary onClick={handleNewOpenForm} to="/wizard/" type="link">
        Подобрать&nbsp;психолога
      </ButtonNewPrimary>
      {isLoggedOff && (
        <>
          <ButtonNewOutline onClick={onClickLoginButton}>
            Войти
          </ButtonNewOutline>
          <ButtonNewOutline onClick={() => navigate('/b2b_signup')}>
            Регистрация для b2b клиентов
          </ButtonNewOutline>
        </>
      )}
    </ButtonGroup>
  )
}
