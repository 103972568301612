import styled from 'styled-components'
import { Button } from '../../../../atoms/Button'
import { Text } from '../../../../atoms/Text'
import { ReactComponent as VideoCallSVG } from './img/video-call.svg'
import { size } from '../../../../constants'

export const VideoCallIcon = styled(VideoCallSVG)`
  display: none;

  @media (max-width: ${size.sm}) {
    display: block;
  }
`

export const Title = styled(Text.Large)`
  @media (max-width: ${size.sm}) {
    font-size: 18px;
    line-height: 26px;
  }
`

export const Wrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 8px;

  @media (max-width: ${size.sm}) {
    padding: 0;
    gap: 32px;
  }
`

export const ButtonGroupWithB2B = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;

  @media (max-width: ${size.sm}) {
    width: 100%;
    gap: 8px;
  }
`

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  @media (max-width: ${size.sm}) {
    width: 100%;
    gap: 8px;
  }
`

export const ButtonGrey = styled(Button.Grey)`
  width: 80px;

  @media (max-width: ${size.sm}) {
    width: 100%;
  }
`

export const ButtonNewPrimary = styled(Button.NewPrimary)`
  width: 80px;

  @media (max-width: ${size.sm}) {
    width: 100%;
  }
`

export const ButtonNewOutline = styled(Button.NewOutline)`
  width: 100%;
`
